.CardData{
    background-color: #ffffff1c;
    /* background-image: url('./../../public/images/carddata.svg'); */
    background-size: 100%;
    background-repeat: no-repeat;
}
.CardData__value{
    color: var(--primaryColor);
    font-weight: bold;
    font-size: 1.7em;
}
.CardData__label{
    color: var(--primaryColor);
    font-weight: bold;
    font-size: .8em;
}

.CardData .CardData__image{
    position: relative;
    display: inline-flex;
}

.CardData .CardData__image::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #ffffff1c;
    top: 15%;
    left: 0;
    transform: translateX(-5px);
}

.CardData .CardData__image::after{
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #ffffff1c;
    bottom: 15%;
    right: -10px;
    transform: translateY(15px);
}