.float_button_parent {
  position: fixed;
  height: auto;
  width: auto;
  z-index: 1080;
  /* background: rgba(255, 0, 0, 0.199); */
  bottom: 10px;
  right: 20px;
  /* pointer-events: none; */
}

.float_btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
  border: none;

  display: flex;

  align-items: center;
  border-radius: 60px;
  height: 36px;
  padding: 0px 14px;
  width: fit-content;
}

.float_box {
  position: absolute;
  bottom: 60px;
  right: 20px;

  width: 300px;
  /* height: calc(100vh - 18px); */
  height: 500px;
  background: #00000073;
  border-radius: 18px;
  box-shadow: 0 2px 12px rgb(0 0 0 / 15%);
  overflow: hidden;
  backdrop-filter: blur(5px);

  transform-origin: bottom right;
  transform: translateY(80px);
  opacity: 0;
  scale: 1;
  pointer-events: none;

  transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
}

.show_float_box {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}

.float_box_head {
  position: relative;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid grey;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close_float_box {
  width: 25px;
  height: 25px;
  background: #ffffff5c;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.msg_box {
  position: relative;
  height: 400px;
  border-bottom: 1px solid grey;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.msg_txt {
  color: #fff;
  width: fit-content;
  background-color: #6c6f71;
  border-radius: 1rem 1rem 1rem 0;
  font-size: 15px;
  max-width: 70%;
  padding: 6px 10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.msg_txt.show {
  opacity: 1;
}

.msg_option {
  background: #fff;
  color: #000;
  border-radius: 5px !important;
  cursor: pointer;
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);

  display: flex;
  justify-content: space-around;
  align-items: center;
  transform: scale(1);
  transition: transform 200ms ease-in-out;
}

.last_option {
  border-radius: 5px 5px 5px 0 !important;
}

.msg_send {
  background: #65a3ff;
  color: #fff;
  border-radius: 1rem 1rem 0 1rem;
  cursor: pointer;
  box-shadow: 0 1px 2px rgb(0 0 0 / 30%);
  text-align: left;
}

.msg_option:hover {
  background: hsla(0, 2%, 91%, 0.509);
  color: #fff;
  transform: scale(0.95);
}

.msg_option span {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

span.user_name {
  display: inline-block;
}

span.user_name:first-letter {
  text-transform: uppercase;
}

.float_box_footer {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.float_box_footer input {
  background: transparent;
  border: none;
  height: 100%;
  width: 90%;
  color: #fff;
}

.float_box_footer input:focus {
  outline: none;
}

.float_box_footer input:disabled {
  background: transparent;
}

.float_box_footer div {
  background: transparent;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.float_box_footer div > svg {
  width: 70%;
}

.msg_box::-webkit-scrollbar {
  width: 2px;
}

.msg_box::-webkit-scrollbar-track {
  background: #f1f1f173;
}

.msg_box::-webkit-scrollbar-thumb {
  background: #888;
}

.msg_box::-webkit-scrollbar-thumb:hover {
  background: #555;
}
