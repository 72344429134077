.imageVS{
    width: 120px;
    height: 120px;
    border-radius: 50%;
}
.scoreResultContainer{
    background-color: #999999;
    color: white;
}
.scoreResultContainer__score{
    border-color: white !important;
}