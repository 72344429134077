.PulseLoader {
    height: 7px;
    width: 228.8px;
    background: linear-gradient(#fbcb4b 0 0),
        linear-gradient(#fbcb4b 0 0),
        #dbdcef;
    background-size: 60% 100%;
    background-repeat: no-repeat;
    animation: PulseLoader-7x9cg2 3s infinite;
 }
 
 @keyframes PulseLoader-7x9cg2 {
    0% {
       background-position: -150% 0,-150% 0;
    }
 
    66% {
       background-position: 250% 0,-150% 0;
    }
 
    100% {
       background-position: 250% 0, 250% 0;
    }
 }