.Navbar{
    height: 75px;
    color: white;
}

.language-change__item--active{
    color: var(--primaryColor);
}

.language-change__item{
    cursor: pointer;
    transition: .2s ease-in;
}
.language-change__item:hover{
    color: var(--primaryColor);
}

/* Navbar Links */
.Navbar__links a{
    color: white;
    text-decoration: none;
}
.Navbar__links a img{
    height: 60px;
}

.Navbar__links a:hover{
    color: var(--primaryColor);
}

.toggleBut__Ele{
    background: var(--primaryColor);  
    border-radius: 10px;
}
.toggleBut {
    cursor: pointer;
}