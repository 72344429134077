.circle-container__item{
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #8b9092;
    transition: .2s ease-in;
}
.circle-container__item--active{
    background-color: var(--primaryColor);
}

.first-title{
    line-height: 1.4em;
}