body {
    margin: 0;
    padding: 0;
    background:
        linear-gradient(90deg, rgba(29, 40, 44, 0.92) 10%, rgba(27, 38, 42, 1) 56%, rgba(71, 79, 82, 1) 150%),
        radial-gradient(circle, rgba(255, 255, 255, 1) 10%, rgba(0, 0, 0, 1) 33%) 32%;
    font-family: 'Poppins', sans-serif;
}

.NavbarContainer {
    width: 57.2%;

}

@media (max-width: 1200px) {
    .NavbarContainer {
        width: 52.2%;
    }
}

@media (max-width: 768px) {
    .AppContainer {
        width: 100vw;
        height: 100vh;
    }

    .NavbarContainer {
        width: 100%;
    }

    .rightContainer .circle-container {
        display: none !important;
    }

}