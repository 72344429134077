.AdminContainer {
    --sideBarWidth: 0;
    background-image: url('./../../../public/images/background-2.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    padding: 0;
    height: 100vh;
}

.AdminContainer__content {
    width: 100%;
    max-height: 100vh;
    overflow: hidden !important;
    overflow-y: auto !important;
}

.AdminContainer__sideBar {
    width: var(--sideBarWidth);
    height: 100vh;
    overflow: hidden;
    transition: .2s ease-in;
}

.AdminContainer__sideBar.open {
    --sideBarWidth: 250px;
}

.AdminContainer__sideBar.open+.AdminContainer__content {
    width: calc(100% - var(--sideBarWidth));
}


@media (max-width: 1200px) {
    .AdminContainer__sideBar {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .AdminContainer__content {
        width: 100%;
    }
    
}

