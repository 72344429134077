:root {
    --sideBarBG: #2C2C2C;
    --sidebarBarBorderColor: #787878;
    --sideBarColor: #ffffff;
    --sideBarActiveColor: #000000;
    --sideBarBGActive: #00000000;
}

.SideBar {
    width: 100%;
    height: 100%;
    background-color: var(--sideBarBG);
    transition: .2s ease-in;
}

.SideBar__brand img {
    width: 110px;
}
.SideBar__navigator{
    height: calc(100% - 120px);
    overflow-y: auto;
}


/* width */
.SideBar__navigator::-webkit-scrollbar {
    width: 3px;
    height: 7px;
    border-radius: 4px;
}

/* Track */
.SideBar__navigator::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
}

/* Handle */
.SideBar__navigator::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 4px;
    transition: .2s ease-in;
}
.SideBar__navigator:hover::-webkit-scrollbar-thumb {
    background: #888;
}



.SideBarItem.active {
    background-color: var(--sideBarColor);
    /* border-right: 2px solid #00000070; */
}

.SideBarItem.active .NavLink__container__title {
    color: var(--sideBarActiveColor);
}

.SideBarItem.active .NavLink__container__icon i {
    color: var(--primaryColor);
}

.SideBar__navigator i {
    font-size: 1.3em;
}

.NavLink__container__title {
    width: 80%;
    transition: .2s ease-in;
}

.NavLink__container__icon {
    width: 20%;
    transition: .2s ease-in;
}
