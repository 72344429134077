.cover {
    position:absolute;
    top: 75px;
    left: 0px;
    height: calc(100vh - 75px);
    display: flex ;
    flex-direction:column ;
    justify-content: flex-start;
    align-items: center;
    gap: 20px ;
    width: 100%;
    background-color: rgba(000, 000, 000, 0.8);
    color: white;
    padding: 10px 0;
}

.mobile__link:hover {
    color:var(--primaryColor);;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}

.but__top {
    transform: rotate(45deg);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform-origin: left;
}
.but__bottom {
    transform: rotate(-45deg);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transform-origin: left;
}

.but__middle {
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
