.CartItem{
    border-bottom: 1px solid #333333;
    transition: .2s ease-in-out;
    cursor: pointer;
}
.CartItem:hover{
    scale: .95;
}

.CartItem img{
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background-color: white; */
}

.CartItem__image{
    position: relative;
    width: 50px;
    height: 50px;
}

.CartItem__image::after{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: attr(data-count);
    width: 20px;
    height: 20px;
    top: 0;
    right: 80%;
    border-radius: 50%;
    color: #333333;
    /* font-weight: bold; */
    font-size: .8em;
    background-color: white;
}

.CartItem i{
    cursor: pointer;
    transition: .2s ease-in-out;
}

.CartItem i:hover{
    scale: 1.1;
}

.CartItem__price{
    color: var(--primaryHoverColor) !important;
}