.GameHistory {
    background-color: #F0F0F050;
    border-radius: 10px;
}
.GameHistory .brand{
    width: 70px;
}

.rdt_Pagination{

}
.rdt_TableRow{
    padding: .5em;
}

.PaymentHistory{
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.GameHistory .voteResult{
    color: var(--primaryColor) !important;
    border-color: var(--primaryColor) !important;
}