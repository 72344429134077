@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.bestPlayerTitle {
    border-radius: 40px;
}

.bestPlayerType {
    background-color: rgb(240, 192, 37);
    color: white;
    border-radius: 8px;
}

.bestPlayerList {
    height: 60vh;
    overflow-y: auto;
    background-color: transparent;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 15px;
    gap: .5em;
    justify-content: center;
    align-items: center;
    border: 1px solid #00000020 !important;
}

/* width */
.bestPlayerList::-webkit-scrollbar {
    width: 10px;
    height: 7px;
    border-radius: 4px;
}

/* Track */
.bestPlayerList::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

/* Handle */
.bestPlayerList::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

/* Handle on hover */
.PaymentHistory::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.bestPlayerListItem {
    border-radius: 4px;
    padding: 2px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: transparent;
    transition: .2s ease;
}

.bestPlayerListItem:hover {
    scale: 1.05;
}

.bestPlayerListItem .ranking {
    display: flex;
    justify-content: center;
    align-items: center;

}

.bestPlayerListItem .ranking div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: transparent;
    color: white;
    font-weight: bold;
    border: 1px dashed;
    user-select: none;

}

.bestPlayerListItem .content {
    background-color: transparent;
    padding: .8em;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none !important;

}

.bestPlayerListItem .content img {
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
}

.bestPlayerListItem .content .content-name {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4em;
    color: #fff;
}

.bestPlayerListItem .content .content-voting {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4em;
}

.bestPlayerListItem .content .content-voting div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .4em;
    padding: 0px .8em;
    background-color: red;
    color: white;
    font-weight: bold;
    height: max-content;
    box-sizing: border-box;
    font-size: .8em;
    border-radius: 4px;
    user-select: none;
}

.bestPlayerListItem .content .content-action {
    display: flex;
    justify-content: center;
    align-items: center;
    translate: 10px 10px;
}

.bestPlayerListItem button {
    border: 1px solid rgb(240, 192, 37);
    background-color: rgb(240, 192, 37);
    color: white;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.bestPlayerListItem button.active {
    border: 1px solid var(--green);
    background-color: var(--green);
    color: white;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}


.refreshVote div {
    padding: 0 .3em;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: .2s ease;
}

.refreshVote div:hover {
    scale: .95;
}