.rdt_Pagination,
.PaymentHistory div {
  background-color: #00000020 !important;
  color: #FAFAFA !important;
}

.PaymentHistory button {
  cursor: pointer !important;
  border: none !important;
  transition: .2s ease-in-out;
}

.PaymentHistory button:hover {
  scale: .9;
}

.PaymentHistory div.rdt_TableCell,
.PaymentHistory div.rdt_TableRow {
  background-color: transparent !important;
}

.PaymentHistory div.rdt_TableCell div,
.PaymentHistory div.rdt_TableRow div {
  background-color: transparent !important;
}

.rdt_TableCol_Sortable div {
  text-transform: uppercase;
}

.rdt_TableCol_Sortable div,
.rdt_TableCol div {
  background-color: transparent !important;
}

/* width */
.PaymentHistory::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

/* Track */
.PaymentHistory::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.PaymentHistory::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.PaymentHistory::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.maj {
  text-transform: uppercase;
}

.cross {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #ffffff50;
  color: var(--white);
  border-radius: 100%;
  transition: 0.1s ease-in;
  position: absolute;
  border: 1px solid ffffff50;
  top: 10%;
  right: 5%;
}

.article_list_hover:hover {
  color: var(--primaryColor);
}


.cross:hover {
  box-shadow: none !important;
  scale: 0.9;
}

.navigationItemBtn {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: var(--primaryColor);
  color: var(--white);
  border-radius: 100%;
  transition: 0.2s ease-in;
}

.navigationItemBtn:hover {
  box-shadow: none !important;
  scale: 0.8;
}

.nassara td {
  width: 25% !important;
}

.articles td {
  width: 25% !important;
}

td {
  text-align: left !important;
  vertical-align: middle !important;
}

.panel div {
  font-weight: bold;
  cursor: pointer;
  width: 40%;
}

.panel .active {
  opacity: 1 !important;
  color: var(--greenhover);
}

.panel div:hover {
  color: var(--greenhover);
  border-bottom: none !important;
}

.panel .active:hover {
  color: var(--greenhover) !important;
}

.rdt_TableHeader {
  font-weight: bold !important;
}

.actionBtn {
  border: none;
  transition: 0.2s ease-in-out;
}

.actionBtn:hover {
  transform: scale(0.9);
}

.actionBtn[disabled]:hover {
  transform: scale(1);
  cursor: not-allowed;
}

body {
  overflow-x: hidden;
}

.ImageViewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #00000088;
  overflow: hidden !important;
  backdrop-filter: blur(2px);
  z-index: 10;
}

.ImageViewer>div {
  z-index: 14;
}

/* popup */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 80%;
  max-width: 1600px;
  height: 90vh;
  max-height: 800px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  overflow: hidden;
  transition: 1s;
  opacity: 0;
}

.popup.active {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}


/*/////***********************************************/




*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}


.tilt-box-wrap {
  transition: all 0.2s ease-out;
  perspective: 1000px
}

.tilt-box-wrap:hover {
  transition: all 0.1 linear;
  transform: scale(1.1);
}

.tilt-box {
  transition: all 0.2s ease-out;
  transform: rotateX(0deg) rotateY(0deg);
  perspective: 1000px;
  transform-style: preserve-3d;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.tilt-box strong {
  transform: translateZ(40px);
}

.t_over {
  width: 33.333%;
  height: 33.333%;
  position: absolute;
  z-index: 1;
}

.t_over:nth-child(1) {
  left: 0;
  top: 0;
}

.t_over:nth-child(2) {
  left: 33.333%;
  top: 0;
}

.t_over:nth-child(3) {
  left: 66.666%;
  top: 0;
}

.t_over:nth-child(4) {
  left: 0;
  top: 33.333%;
}

.t_over:nth-child(5) {
  left: 33.333%;
  top: 33.333%;
}

.t_over:nth-child(6) {
  left: 66.666%;
  top: 33.333%;
}

.t_over:nth-child(7) {
  left: 0;
  top: 66.666%;
}

.t_over:nth-child(8) {
  left: 33.333%;
  top: 66.666%;
}

.t_over:nth-child(9) {
  left: 66.666%;
  top: 66.666%;
}

.t_over:nth-child(1):hover~.tilt-box {
  transform: rotateX(-20deg) rotateY(20deg);
}

.t_over:nth-child(2):hover~.tilt-box {
  transform: rotateX(-20deg) rotateY(0deg)
}

.t_over:nth-child(3):hover~.tilt-box {
  transform: rotateX(-20deg) rotateY(-20deg)
}

.t_over:nth-child(4):hover~.tilt-box {
  transform: rotateX(0deg) rotateY(20deg)
}

.t_over:nth-child(5):hover~.tilt-box {
  transform: rotateX(0deg) rotateY(0deg)
}

.t_over:nth-child(6):hover~.tilt-box {
  transform: rotateX(0deg) rotateY(-20deg)
}

.t_over:nth-child(7):hover~.tilt-box {
  transform: rotateX(20deg) rotateY(20deg);
}

.t_over:nth-child(8):hover~.tilt-box {
  transform: rotateX(20deg) rotateY(0deg)
}

.t_over:nth-child(9):hover~.tilt-box {
  transform: rotateX(20deg) rotateY(-20deg)
}


.downloadBtn {
  border-radius: 5px !important;
  border: none !important;
  transition: .2s ease-in-out;
  color: var(--white) !important;
  background-color: var(--primaryColor) !important;
  height: 35px;
  width: 30% !important;

}

.downloadBtn:hover {
  scale: .98;
  background-color: var(--primaryHoverColor);
}

