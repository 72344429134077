@import url('https://fonts.cdnfonts.com/css/poppins');

:root {
  --green: #008A20;
  --greenhover: #01a628;
  --white: #ffffff;
  --gray: #AAAAAA;
  --black20: rgba(0, 0, 0, 0.2);
  --white10: rgba(255, 255, 255, 0.1);
  --black: #232440;
  --orange: #FF7720;
  --yellow: #FF9900;
  --bg: #f8f9fa;

  --primaryColor: #fbcb4b;
  --primaryHoverColor: #d89f04;
  --inputBorderColor: #ffffff56;
  --buttonRadius: 6px;
  --selectionColor: #ffffff56;
  --disabledColor: #cccccc;
}

body {
  margin: 0;
  padding: 0;
  background: linear-gradient(90deg, rgba(29, 40, 44, 0.92) 10%, rgba(27, 38, 42, 1) 56%, rgba(71, 79, 82, 1) 150%),
    radial-gradient(circle, rgba(255, 255, 255, 1) 10%, rgba(0, 0, 0, 1) 33%) 32%;
  font-family: 'Poppins', sans-serif;
}

@media (max-width:768px) {
  body {
    background: rgba(29, 40, 44, 0.92);
  }
}

::selection {
  background-color: var(--selectionColor);
}

button:disabled {
  cursor: not-allowed !important;
}

.AppContainer {
  background-image: url('./../public/images/player-bg.png'), url('./../public/images/background-2.png') !important;
  background-repeat: no-repeat;
  background-size: 80%, cover;
  background-position: 50% -50%;
  margin: 0;
  padding: 0;
  height: 100vh;
}

@media (min-width: 360px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% 125%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

@media (min-width: 374px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% 132%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

@media (min-width: 390px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% 122%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

/* Tablettes */
@media (min-width: 765px) and (min-height: 1020px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% 150%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

@media (min-width: 540px) and (min-height: 720px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% 150%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

/* Galaxy Fold */
@media (max-width: 359px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% 120%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

/* Nest Hubs */
@media (min-width: 1024px) and (min-height: 600px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% -85%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

@media (min-width: 1024px) and (min-height: 800px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% -120%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

/* iPad Pro */
@media (min-width: 1024px) and (min-height: 1000px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% 155%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}


/* Nest Hubs */
@media (min-width: 1366px) and (min-height: 600px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% -15%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}

/* Nest Hubs */
@media (min-width: 1600px) and (min-height: 600px) {
  .AppContainer {
    background-size: 100%, cover;
    background-position: 50% -35%;
  }

  .joinTeamBtn {
    min-width: max-content;
    /* font-size: .8em; */
  }
}


.custom-container {
  width: 80%;
}

@media (max-width: 1200px) {
  .custom-container {
    width: 88%;
  }
}

@media (max-width: 576px) {
  .custom-container {
    width: 95%;
  }
}

.btn.btn-primary,
.btn.btn-primary:active,
input[type='submit'] {
  color: #1b262a !important;
  background-color: var(--primaryColor) !important;
  border-radius: 6px !important;
  border: 1px solid var(--primaryColor) !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: .2s ease-in !important;
}

.btn.btn-primary:hover {
  background-color: var(--primaryHoverColor) !important;
  border-color: var(--primaryHoverColor) !important;
}

input[type="text"],
input[type='password'] {
  color: white !important;
  transition: .2s ease-in !important;
}

input[type='checkbox'] {
  transition: .2s ease-in;
  accent-color: var(--primaryColor);
}

.PhoneInputInput {
  transition: .2s ease-in
}

.form-control:focus,
.PhoneInputInput:focus {
  color: #212529;
  background-color: transparent;
  border-color: white;
  outline: 0;
  box-shadow: none;
  caret-color: white;
}

.btn:disabled {
  cursor: not-allowed !important;
  background-color: var(--disabledColor) !important;
  border-color: var(--disabledColor) !important;
}


/** Switch
         -------------------------------------*/

.switch input {
  position: absolute;
  opacity: 0;
}

/**
               * 1. Adjust this to size
               */

.switch {
  display: inline-block;
  font-size: 20px;
  /* 1 */
  height: 1em;
  width: 2em;
  background: #BDB9A6;
  border-radius: 1em;

  cursor: pointer;
  transition: background 300ms ease;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #FFFFFF !important;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked+div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.switch:has(> input:checked) {
  background: var(--primaryColor);
}

.actionBtns {
  width: 80% !important;
}

.switch input[type="checkbox"] {
  display: none;
}

.switch input[type="checkbox"]+label .indicator {
  content: '';
  position: relative;
  border: none;
  width: 25px;
  height: 25px;
  margin-right: 0.7rem;
  flex-shrink: 0;
  background-color: #FFF !important;
  /* box-shadow: 0 0 0 0 #F25F20; */
}

.switch input[type="checkbox"]+label {
  cursor: pointer;
}

.switch input[type="checkbox"]:checked+label .indicator {
  background-color: #F25F20;
  box-shadow: inset 0px 0px 0 3px #f25f20, inset 0px 0px 0 7px #fff;
}