@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App {
  width: 100%;
  margin: 50rem auto;
  height: 50%;
  z-index: 10;
}

.item{
  background-color: var(--white);
  width: 50%;
  border-radius: 25px;
  padding: 20px;

}

.slide .nom{
  font-weight: bold;
  font-size: 17px;
  color: var(--primaryColor);
}

.slide .prix{
  font-weight: bold;
  font-size: 25px;

}

.slide img {
  width: 25%;
  margin: 0 auto;
}

.slide {
  transform: scale(0.2);
  transition: transform 300ms;
  opacity: 0.5;
}

.activeSlide {
  transform: scale(1);
  opacity: 1;
}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;
}

.navigationItemBtn {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #ffffff50 !important;
  color: var(--white);
  border-radius: 100%;
  transition: 0.2s ease-in;
}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 5%;
  top: 50%;
}

.prev {
  left: 5%;
  top: 50%;
}

.compt{
  border-radius: 100%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  position: absolute;
  right: 27%;
  bottom: 5px;
  color: var(--primaryColor);
}

@media screen and (max-width:800px){
  .slide img {
    width: 40%;
  }
}
