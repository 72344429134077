.CardPaymentForm{
    background-color: white;
}
.CardPaymentForm::selection{
    background-color: #00000050;
}

.backButton{
    cursor: pointer;
    transition: .2s ease-in;
    font-size: 1.2em;
}

.backButton:hover{
    transform: translateX(-4px);
}