.OtpForm .alert{
    border-radius: 4px !important;
    padding: 5px !important;
}

.OtpForm{
    
}

.form-control-otp{
    /* width: 100% !important; */
}