.openCart{
    background-color: transparent;
    color: white !important;
    transition: .2s ease-in;
}
.openCart:hover{
    scale: 1.05;
}

.ShopItemContainer{
    width: 33%;
}

@media (max-width: 812px) {
    .ShopItemContainer{
        width: 50%;
    }
}

@media (max-width: 512px) {
    .ShopItemContainer{
        width: 100%;
    }
}