.NoVote .commingSoon {
    transition: .2s ease-in-out;
    animation: commingSoon 1s ease-in-out 300ms 3 alternate-reverse;
}

@keyframes commingSoon {
    0% {
        transform: scale(.95);
        color: #ffffff20;
    }

    30% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1);
    }

    80% {
        transform: scale(1);
    }
}

.cardPlus__contain {
    /* overflow-y: auto; */
    counter-reset: plusCounter;
}

/* width */
.cardPlus__contain::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
.cardPlus__contain::-webkit-scrollbar-track {
    background: #474f5250;
}

/* Handle */
.cardPlus__contain::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
.cardPlus__contain::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.cardPlus {
    position: relative;
    font-weight: bold;
    counter-increment: plusCounter;
}

.cardPlus__content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #474f52;
    color: #94999b;
    margin-left: 30px;
    border-radius: 4px;
    height: 25px;
    user-select: none;
}

.cardPlus::after {
    position: absolute;
    content: " ";
    width: 8px;
    height: 4px;
    left: 25px;
    top: calc(50% - 1px);
    background-color: #474f52;
}

.cardPlus::before {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: counter(plusCounter);
    width: 25px;
    height: 25px;
    color: #94999b;
    border-radius: 50%;
    background-color: #474f52;
}